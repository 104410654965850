<template>
  <div>
    <pending-order-invoice
      v-if="pendingOrder && !showInvoice"
      :loading="loading"
      :parsedOrder="parsedOrder"
      :pendingOrder="pendingOrder"
      :handleShowDetails="handleShowDetails"
    />
    <order-invoice v-if="showInvoice" :order="order" />
    <LoginModal
      successMessage="Ya tienes acceso a los detalles de esta orden"
      :successCallback="successCallback"
    />

    <div v-if="isError && !loading && !pendingOrder" class="d-flex justify-content-center">
      <div>
        <h1>Acceso denegado</h1>
        <p>Por favor, inicia sesión</p>

        <div class="mt-4">
          <b-button
            block
            variant="primary"
            @click="() => $bvModal.show('login-modal')"
            >Iniciar sesión</b-button
          >

          <b-button block variant="outline-primary" @click="$router.go(-1)"
            >Volver</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Directives
import { mapGetters, mapActions } from "vuex"

// Components
import LoginModal from "@/@core/components/LoginModal.vue"
import OrderInvoice from "@/@core/components/OrderInvoice.vue"
import PendingOrderInvoice from "@/@core/components/PendingOrderInvoice.vue"

// Mixins
import useAppConfig from "@core/app-config/useAppConfig"
import messagesMixin from "@/@core/mixins/messagesMixin"

export default {
  components: {
    LoginModal,
    OrderInvoice,
    PendingOrderInvoice,
  },
  data() {
    return {
      order: {},
      isError: true,
      loading: false,
    }
  },
  mixins: [messagesMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loading = true
      if (vm.currentUser) {
        vm.fetchOrder(to.params.id)
          .then((response) => {
            vm.isError = false
            vm.order = response
          })
          .catch(() => {
            vm.fetchOrderByPendingOrder(to.params.id)
              .then((response) => {
                vm.isError = false
                vm.order = response
              })
              .catch(() => {
                vm.isError = true
                vm.fetchPendingOrder({
                  id: to.params.id,
                }).catch(() => {
                  vm.warningToast(
                    "Advertencia",
                    "Para acceder a esta orden debes iniciar sesión"
                  )
                  vm.$bvModal.show("login-modal")
                })
              })
          })
          .finally(() => {
            vm.loading = false
          })
      } else {
        vm.fetchPendingOrder({
          id: to.params.id,
        })
          .catch(() => {
            vm.warningToast(
              "Advertencia",
              "Para acceder a esta orden debes iniciar sesión"
            )
            vm.$bvModal.show("login-modal")
          })
          .finally(() => {
            vm.loading = false
          })
      }
    })
  },
  setup() {
    const { skin } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout")
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("pendingOrders", ["pendingOrder"]),

    showInvoice() {
      return this.currentUser && !this.isError
    },

    parsedOrder() {
      if (!this.pendingOrder) return null

      return this.pendingOrder.pending_order
    },
  },
  methods: {
    ...mapActions("orders", ["fetchOrder", "fetchOrderByPendingOrder"]),
    ...mapActions("pendingOrders", ["fetchPendingOrder"]),

    successCallback() {
      window.location.reload()
    },

    handleShowDetails() {
      this.loading = true
      if (this.currentUser) {
        let payment_type = null

        if (this.parsedOrder.order_payments_attributes) {
          payment_type =
            this.parsedOrder.order_payments_attributes[0].payment_type
        } else {
          payment_type = this.parsedOrder.payment_type
        }

        this.fetchOrderByPendingOrder(this.$route.params.id)
          .then((response) => {
            this.fetchOrder(response.id)
              .then((response) => {
                this.order = response
                this.isError = false
              })
              .catch(() => {
                window.location.reload()
              })
          })
          .catch(() => {
            if (
              payment_type === "codi" ||
              payment_type === "bankcard" ||
              payment_type === "bank_deposit"
            ) {
              this.$swal({
                title: "Pedido pendiente",
                text: "El pedido esta siendo procesado o no ha sido pagado. Intentalo de nuevo mas tarde",
                icon: "warning",
                customClass: {
                  confirmButton: "btnx btn-primary",
                },
                buttonsStyling: false,
              })
            } else {
              this.$swal({
                title: "Error",
                text: "El pedido ha sido cancelado. Intentalo de nuevo mas tarde",
                icon: "error",
                customClass: {
                  confirmButton: "btnx btn-primary",
                },
                buttonsStyling: false,
              })
            }
            this.fetchPendingOrder({
              id: this.$route.params.id,
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$bvModal.show("login-modal")
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
