<template>
  <b-modal
    id="login-modal"
    body-class="py-2 px-4"
    centered
    hide-footer
    @hidden="resetForm"
  >
    <div class="d-flex justify-content-center">
      <b-img :src="appLogoImage" alt="logo" width="250" class="logo" />
    </div>
    <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
      ¡Bienvenido! 👋
    </b-card-title>
    <b-card-text class="mb-2"> Tu nueva forma de pagar y cobrar. </b-card-text>

    <!-- form -->
    <validation-observer ref="loginForm" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="login">
        <!-- email -->
        <b-form-group label="Email o Teléfono celular" label-for="login-email">
          <validation-provider
            #default="{ errors, valid }"
            name="Email"
            vid="email"
            rules="required|email_or_phone"
          >
            <b-form-input
              id="login-email"
              v-model="userEmail"
              :state="errors.length > 0 ? false : valid ? true : null"
              name="login-email"
              tabindex="1"
              autocomplete="username"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-password">Contraseña</label>
            <b-link :to="{ name: 'auth-forgot-password' }">
              <small>¿Olvidaste tu contraseña?</small>
            </b-link>
          </div>
          <validation-provider
            #default="{ errors, valid }"
            name="Password"
            vid="password"
            rules="required|min:6"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="login-password"
                v-model="password"
                :state="errors.length > 0 ? false : valid ? true : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="Password"
                tabindex="2"
                autocomplete="current-password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button type="submit" variant="primary" class="mt-3" block>
          Iniciar sesión
        </b-button>
      </b-form>
    </validation-observer>

    <b-card-text class="text-center mt-2">
      <span>¿Quieres comprar en línea? </span>
      <b-link
        :to="{
          name: 'auth-register-client',
          query: { token: this.token },
        }"
      >
        <h4>
          <span class="badge badge-success">&nbsp;Registrate como cliente</span>
        </h4>
      </b-link>
    </b-card-text>

    <b-card-text class="text-center mt-2">
      <span>¿Tienes un negocio y quieres usar nuestro sistema? </span>
      <b-link :to="{ name: 'auth-register-establishment' }">
        <h4>
          <span class="badge badge-info">&nbsp;Registrate como negocio</span>
        </h4>
      </b-link>
    </b-card-text>

    <div class="divider-text mt-2" />

    <div class="auth-footer-btn d-flex justify-content-center text-center">
      <p>
        Al registrarse, acepta nuestros:
        <b-button block :to="{ name: 'terms-of-use' }" size="sm">
          <i class="fas fa-chart-line mr-1" />
          Términos y condiciones
        </b-button>
        <b-button block :to="{ name: 'privacy-policy' }" size="sm">
          <i class="fas fa-chart-line mr-1" />
          Aviso de Privacidad
        </b-button>
      </p>
    </div>
  </b-modal>
</template>

<script>
// Directives
import { mapActions, mapMutations } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"

// Mixins
import messagesMixin from "../mixins/messagesMixin"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"

// Utils
import { $themeConfig } from "@themeConfig"
import useJwt from "@/auth/jwt/useJwt"
import { attachReferralAPI } from "../libs/api/user"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, messagesMixin],
  props: {
    successMessage: {
      type: String,
      required: false,
    },
    successCallback: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      password: "",
      userEmail: "",
      token: this.$route.query?.token,
    }
  },

  setup() {
    const { appName, appLogoImage } = $themeConfig.app

    function resetForm() {
      this.password = ""
      this.userEmail = ""
    }

    return {
      appName,
      appLogoImage,
      resetForm,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    async login() {
      let emailValidation = false
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.userEmail)) {
        emailValidation = true
      }

      try {
        const isValidated = await this.$refs.loginForm.validate()

        if (isValidated) {
          const response = await this.signIn({
            email: emailValidation === true ? this.userEmail : null,
            phone_number: emailValidation === false ? this.userEmail : null,
            password: this.password,
          })

          useJwt.setToken(response.token)
          useJwt.setRefreshToken(response.token)
          localStorage.setItem("userData", JSON.stringify(response))

          const token = this.$route.query?.token
          if (token) {
            const decodedToken = decodeURIComponent(token)
            attachReferralAPI({ encrypted_message: decodedToken })
          }

          if (this.successMessage) {
            this.successToast("Iniciado sesión exitoso", this.successMessage)
          }
          if (this.successCallback) {
            this.successCallback()
          }

          this.$bvModal.hide("login-modal")
        }
      } catch (e) {
        this.handleFetchError(
          e.response.data,
          "Ocurrió un error al iniciar sesión"
        )
      }
    },
  },
}
</script>
